import React, { useContext, useEffect, useState } from 'react'
import ButtonLink from '@components/ButtonLink'
import ErrorWrapper from '@components/UI/ErrorWrapper'
import { cls } from '@utils/classnames'
import { isMobile, isMq3 } from '@utils/mediaQueries'

import ProductWishlist, { ProductWishlistConfig } from '../../Wishlist/ProductWishlistWidget/ProductWishlistWidget'
import { HeaderContext } from '../HeaderContext'
import LanguageSwitcher from '../LanguageSwitcher'
import { CountryLanguageSwitcher } from '../LanguageSwitcher/LanguageSwitcherCountry'
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { NavigationSectionModel, NavigationSubLink } from '../Navigation.d.ts'
import NavLanguageSwitcher from '../NavLanguageSwitcher'
import Search, { SearchConfig } from '../Search'

import NavigationSection from './NavigationSection'

interface NavigationProperties {
  countryLanguageSwitcherConfig: CountryLanguageSwitcher
  searchConfig: SearchConfig
  productWishlistConfig: ProductWishlistConfig
  navigationStructure: NavigationSectionModel[]
}

const Navigation: React.FC<NavigationProperties> = ({
  countryLanguageSwitcherConfig,
  navigationStructure,
  searchConfig,
  productWishlistConfig,
}: NavigationProperties) => {
  const [openAccordion, setOpenAccordion] = useState<null | string>(null)
  const isMobileScreen = isMobile()
  const isMq3Screen = isMq3()

  const {
    isHeaderOpened,
    headerDropdownContent,
    setNavigationTeaserData,
    headerDataJson,
    setHeaderDropdownContent,
    setCurrentSectionId,
  } = useContext(HeaderContext)

  const { countrylanguageswitcher } = headerDataJson[':items']
  const currentCountryLanguageSwitcher = headerDataJson['languageSwitcher']
  const hideLanguageSwitcher = headerDataJson['hideLanguageSwitcher']
  const isTabletOrMobileScreen = Boolean(isMobileScreen || isMq3Screen)

  useEffect(() => {
    !openAccordion &&
      navigationStructure?.map((section: NavigationSectionModel) => {
        if (section.navigationSubLinks?.some((subLink: NavigationSubLink) => subLink.active)) {
          handleAccordionToggle(section)
        }
      })
  }, [])

  function openLanguageSwitcher() {
    setHeaderDropdownContent('languageSwitcher')
  }

  function handleAccordionToggle(clickedAccordion: NavigationSectionModel) {
    const { teaser, id } = clickedAccordion
    setOpenAccordion((currentOpenAccordion) => {
      const isClickedAccordionOpen = Boolean(currentOpenAccordion === id)
      setNavigationTeaserData(isClickedAccordionOpen ? {} : { teaser })
      setCurrentSectionId(id)

      return isClickedAccordionOpen ? 'notAKeyButTruthy' : id
    })
  }

  return (
    <nav
      className={cls({
        'cmp-navigation': true,
        'cmp-navigation--is-opened': isHeaderOpened,
      })}
    >
      {headerDropdownContent === 'navigation' ? (
        <div className="cmp-navigation__container">
          {headerDropdownContent === 'navigation' ? (
            <div className="cmp-navigation__items">
              {navigationStructure.map((section) => (
                <ErrorWrapper key={section.id}>
                  <NavigationSection
                    navigationItem={section}
                    open={openAccordion === section.id}
                    onToggle={handleAccordionToggle}
                    sectionId={section.id}
                  />
                </ErrorWrapper>
              ))}
              {countrylanguageswitcher && !isTabletOrMobileScreen ? (
                <ButtonLink
                  icon="ri-global-line"
                  text={countrylanguageswitcher.countryPageLabel}
                  className="cmp-navigation__items-languageswitcher"
                  onClick={openLanguageSwitcher}
                />
              ) : null}
            </div>
          ) : null}
          <div className="cmp-nav-language-switcher__wrapper">
            <InHeaderLanguageSwitches
              currentCountryLanguageSwitcher={currentCountryLanguageSwitcher}
              countrylanguageswitcher={countrylanguageswitcher}
              onOpenLanguageSwitcher={openLanguageSwitcher}
              show={!hideLanguageSwitcher && isTabletOrMobileScreen}
            />
          </div>
        </div>
      ) : null}
      {headerDropdownContent === 'languageSwitcher' && countrylanguageswitcher ? (
        <LanguageSwitcher config={countryLanguageSwitcherConfig} />
      ) : null}
      {headerDropdownContent === 'search' ? <Search config={searchConfig} /> : null}
      {headerDropdownContent === 'productWishlistWidget' ? <ProductWishlist config={productWishlistConfig} /> : null}
    </nav>
  )
}

function InHeaderLanguageSwitches({
  currentCountryLanguageSwitcher,
  countrylanguageswitcher,
  onOpenLanguageSwitcher,
  show,
}) {
  return (
    <>
      {countrylanguageswitcher ? (
        <ButtonLink
          icon="ri-global-line"
          text={countrylanguageswitcher.countryPageLabel}
          className="cmp-navigation__items-languageswitcher"
          onClick={onOpenLanguageSwitcher}
        />
      ) : (
        <div />
      )}
      {show && (
        <ul className="cmp-nav-language-switcher__list">
          {currentCountryLanguageSwitcher?.map(
            (language: { displayName: string; title: string; link: { externalizedURL: string }; active: boolean }) => {
              return (
                <NavLanguageSwitcher
                  key={language.displayName}
                  title={language.title}
                  link={{
                    externalizedURL: language.link.externalizedURL,
                  }}
                  active={language.active}
                  displayName={language.displayName}
                  showLanguageSwitcherOnMobile={true}
                />
              )
            },
          )}
        </ul>
      )}
    </>
  )
}

export default Navigation
