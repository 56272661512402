/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useEffect, useState } from 'react'
import ButtonLink from '@components/ButtonLink'
import NavigationWrapper from '@components/Header/NavigationWrapper'
import { triggerAnalytics } from '@components/Wishlist/ProductWishlistWidget'
import { getWishlist, WishlistEvent, WishlistProduct } from '@components/Wishlist/WishlistStorage'
import { useOnScrolls } from '@hooks/useOnScroll'
import { cls } from '@utils/classnames'

import Breadcrumb from './Breadcrumb/Breadcrumb'
import Hamburger from './Hamburger'
import { HeaderContext } from './HeaderContext'
import Logo from './Logo'
import MainMenu from './MainMenu'
import Navigation from './Navigation'
import NavigationTeaser from './NavigationTeaser'
import NavLanguageSwitcher from './NavLanguageSwitcher'
import Separator from './Separator'

// eslint-disable-next-line sonarjs/cognitive-complexity
const Header = ({ ariaNavLabel }): JSX.Element => {
  const {
    isHeaderOpened,
    headerDropdownContent,
    navigationTeaserData,
    setIsHeaderOpened,
    setHeaderDropdownContent,
    headerDataJson,
    topNavigationData,
  } = useContext(HeaderContext)

  const { logo, countrylanguageswitcher, search, navigation, menu, productwishlistwidget } = headerDataJson[':items']
  const disableHideOnScroll = headerDataJson['disableHideOnScroll']
  const breadcrumb = headerDataJson['breadcrumb']
  const currentCountryLanguageSwitcher = headerDataJson['languageSwitcher']
  const hideLanguageSwitcher = headerDataJson['hideLanguageSwitcher']
  const showLanguageSwitcherOnMobile = headerDataJson['showLanguageSwitcherOnMobile']
  const { isScrolled, direction } = useOnScrolls()
  const [isBlinking, setIsBlinking] = useState(false)

  const [wishlist, setWishlist] = useState<WishlistProduct[]>(getWishlist())

  const hideLogo = headerDataJson['hideLogo']

  useEffect(() => {
    const headerElement = document.querySelector('.cmp-header')
    if (!headerElement) {
      return
    }

    headerElement.classList[isScrolled ? 'add' : 'remove']('cmp-header--is-scrolled')

    if (!disableHideOnScroll) {
      headerElement.classList[isScrolled && direction == 'up' && !isHeaderOpened ? 'add' : 'remove']('cmp-header--hide')
    }

    const event = new CustomEvent('headerIsHidden', {
      detail: { isOpened: isScrolled && direction == 'up' && !isHeaderOpened },
    })
    headerElement.dispatchEvent(event)

    headerElement.classList[isHeaderOpened ? 'add' : 'remove']('cmp-header--is-opened')

    headerElement.classList[headerDropdownContent === 'languageSwitcher' ? 'add' : 'remove'](
      'cmp-header-country-language-switcher--is-opened',
    )

    headerElement.classList[headerDropdownContent === 'search' ? 'add' : 'remove']('cmp-header-search--is-opened')

    headerElement.classList[headerDropdownContent === 'productWishlistWidget' ? 'add' : 'remove'](
      'cmp-product-wishlist--is-opened',
    )

    headerElement.classList[
      !Boolean(topNavigationData) && Boolean(navigationTeaserData?.teaser?.link) && isHeaderOpened ? 'add' : 'remove'
    ]('cmp-header--invert-font-color')
  }, [isScrolled, isHeaderOpened, navigationTeaserData, topNavigationData, direction])

  useEffect(() => {
    const bodyElement = document.querySelector('body')

    if (!bodyElement) {
      return
    }

    bodyElement.classList[isHeaderOpened ? 'add' : 'remove']('no-scroll')
  }, [isHeaderOpened])

  useEffect(() => {
    window.addEventListener('wishlistUpdated', (event: WishlistEvent) => {
      if (event.data.length > wishlist.length) {
        setIsBlinking(true)
        setTimeout(() => setIsBlinking(false), 2000)
        setWishlist(event.data)
      } else {
        setWishlist(event.data)
      }
    })
  }, [])

  function handleOnClick() {
    setIsHeaderOpened(!isHeaderOpened)
    setHeaderDropdownContent('navigation')
  }

  function handleCountrySwitchClick() {
    setIsHeaderOpened(!isHeaderOpened)
    setHeaderDropdownContent('languageSwitcher')
  }

  function handleSearchClick() {
    setIsHeaderOpened(!isHeaderOpened)
    setHeaderDropdownContent('search')
  }

  function handleWishlistClick() {
    setIsHeaderOpened(!isHeaderOpened)
    setHeaderDropdownContent('productWishlistWidget')
    triggerAnalytics('view wishlist click', wishlist)
  }

  function closeHeader() {
    setIsHeaderOpened(!isHeaderOpened)
  }

  return (
    <>
      {!hideLogo ? (
        <Logo
          backgroundImage={logo?.backgroundImage}
          foregroundImage={logo?.foregroundImage}
          altText={logo?.altText}
          logoLink={logo?.logoLink}
        />
      ) : (
        <div></div>
      )}

      <nav aria-label={ariaNavLabel}>
        <ul className="cmp-header__menu-items" aria-label={ariaNavLabel}>
          {countrylanguageswitcher && !hideLogo && !isHeaderOpened ? (
            <li
              className={cls({
                'cmp-header__menu-item': true,
                'cmp-header__menu-item--hide-on-mobile': !showLanguageSwitcherOnMobile,
              })}
            >
              <ButtonLink
                icon="ri-global-line"
                text={showLanguageSwitcherOnMobile ? undefined : countrylanguageswitcher.countryPageLabel}
                onClick={handleCountrySwitchClick}
              />
            </li>
          ) : null}
          {!hideLanguageSwitcher && !isHeaderOpened && currentCountryLanguageSwitcher ? (
            <li
              className={cls({
                'cmp-header__menu-item': true,
                'cmp-header__menu-item--hide-on-mobile': !showLanguageSwitcherOnMobile,
              })}
            >
              <ul className={cls({ 'cmp-header__menu-item--is-language-switcher': true })}>
                {currentCountryLanguageSwitcher?.map(
                  (language: {
                    displayName: string
                    title: string
                    link: { externalizedURL: string }
                    active: boolean
                  }) => {
                    return (
                      <NavLanguageSwitcher
                        key={language.displayName}
                        title={language.title}
                        link={{
                          externalizedURL: language.link.externalizedURL,
                        }}
                        active={language.active}
                        displayName={language.displayName}
                        showLanguageSwitcherOnMobile={showLanguageSwitcherOnMobile}
                      />
                    )
                  },
                )}
              </ul>
            </li>
          ) : null}

          {productwishlistwidget && wishlist.length > 0 && !isHeaderOpened ? (
            <li
              className={cls({
                'cmp-header__menu-item': true,
                'cmp-header__menu-item--wishlist': true,
                'cmp-header__menu-item--wishlistBlink': isBlinking,
              })}
            >
              <ButtonLink
                icon="ri-heart-line"
                text={wishlist.length.toString()}
                onClick={handleWishlistClick}
              ></ButtonLink>
            </li>
          ) : null}

          {search && !isHeaderOpened ? (
            <li className="cmp-header__menu-item cmp-header__menu-item--search">
              <ButtonLink icon="ri-search-line" text={search.buttonLabel} onClick={handleSearchClick}></ButtonLink>
            </li>
          ) : null}

          {countrylanguageswitcher && hideLogo && !isHeaderOpened ? (
            <li className="cmp-header__menu-item">
              <ButtonLink
                icon="ri-global-line"
                text={countrylanguageswitcher.countryPageLabel}
                onClick={handleCountrySwitchClick}
              />
            </li>
          ) : null}

          {menu && !isHeaderOpened ? (
            <>
              <li className="cmp-header__menu-item cmp-header__menu-item--hide-on-mobile">
                <Separator />
              </li>

              <li className="cmp-header__menu-item cmp-header__menu-item--hide-on-mobile">
                <MainMenu
                  onClick={handleOnClick}
                  buttonLabel={menu.buttonLabel}
                  ariaLabel={navigation?.accessibilityLabel}
                />
              </li>

              <li className="cmp-header__menu-item cmp-header__menu-item--hide-on-desktop">
                <Hamburger onClick={handleOnClick} ariaLabel={navigation?.accessibilityLabel} />
              </li>
            </>
          ) : null}

          {isHeaderOpened && (
            <li className="cmp-header__menu-item cmp-header__menu-item--close">
              <ButtonLink icon="ri-close-line" onClick={closeHeader} />
            </li>
          )}
        </ul>
      </nav>

      <Navigation
        countryLanguageSwitcherConfig={countrylanguageswitcher}
        searchConfig={search}
        productWishlistConfig={productwishlistwidget}
        navigationStructure={navigation?.[':items'] ? Object.values(navigation[':items']) : []}
      />
      {headerDropdownContent === 'navigation' && (
        <>
          <NavigationTeaser isHeaderOpened={isHeaderOpened} navigationTeaserData={navigationTeaserData} />
          <NavigationWrapper isHeaderOpened={isHeaderOpened} topNavigationData={topNavigationData} />
        </>
      )}

      {!isHeaderOpened && breadcrumb ? <Breadcrumb items={breadcrumb?.items} /> : null}
    </>
  )
}

export default Header
