// eslint-disable-next-line sonarjs/cognitive-complexity
;(function () {
  'use strict'

  function onDocumentReady() {
    const productHeroElement = document.querySelector('.cmp-product-hero')
    if (!productHeroElement) return

    window.addEventListener('wishlistMaximumReached', () => {
      productHeroElement.classList.add('cmp-product-hero--hasErrors')
    })

    window.addEventListener('wishlistErrorCleared', () => {
      productHeroElement.classList.remove('cmp-product-hero--hasErrors')
    })
  }

  if (document.readyState !== 'loading') {
    onDocumentReady()
  } else {
    document.addEventListener('DOMContentLoaded', onDocumentReady)
  }
})()
