import { useEffect, useState } from 'react'
import { registerComponent } from 'react-register-dom'
import { triggerAnalytics } from '@components/Wishlist/ProductWishlistWidget/ProductWishlistWidget'
import {
  addProductToWishlist,
  getWishlist,
  isProductInWishlist,
  removeProductFromWishlist,
  WishlistEvent,
  WishlistProduct,
} from '@components/Wishlist/WishlistStorage'
import { cls } from '@utils/classnames'

interface AddToWishlistProperties {
  sku: string
  title: string
  price: string
  link: string
  downloadLink?: string
  thumbnail: any
  catalog: string
  locale: string
  brandcodes: string
}

const AddToWishlist: React.FC<AddToWishlistProperties> = ({
  sku,
  title,
  price,
  link,
  thumbnail,
  catalog,
  locale,
  brandcodes,
}) => {
  const [wishlist, setWishlist] = useState<WishlistProduct[]>(getWishlist())
  const [isFilled, setIsFilled] = useState<boolean>(isProductInWishlist(sku))

  useEffect(() => {
    window.addEventListener('wishlistUpdated', (event: WishlistEvent) => {
      setIsFilled(isProductInWishlist(sku))
      setWishlist(event.data)
    })
  }, [])

  const product: WishlistProduct = {
    sku: sku,
    title: title,
    price: JSON.parse(price),
    link: link,
    thumbnail: JSON.parse(thumbnail).galleryImages[0].thumbnail,
    catalog: catalog,
    locale: locale,
    brandCodes: brandcodes,
  }

  function toggleProductWishlistClick() {
    if (isProductInWishlist(sku)) {
      const productId = wishlist.findIndex((wishlistItem) => wishlistItem.sku === product.sku)
      setIsFilled(false)
      triggerAnalytics('remove product from wishlist click', product)
      removeProductFromWishlist(productId)
    } else {
      triggerAnalytics('add product to wishlist click', product)
      addProductToWishlist(product)
    }
  }

  return (
    <div className="cmp-product-hero__wishlist">
      <button className="cmp-product-hero__wishlist--button" onClick={toggleProductWishlistClick}>
        <i
          className={cls({
            'cmp-product-hero__wishlist--icon': true,
            'cmp-product-hero__wishlist--filled ri-heart-fill': isFilled,
            'ri-heart-line': !isFilled,
          })}
        ></i>
      </button>
    </div>
  )
}

registerComponent('AddToWishlist', AddToWishlist)

export default AddToWishlist
