import React, { useEffect, useRef } from 'react'
import ButtonLink from '@components/ButtonLink'

interface ProductWishlistDialogProperties {
  isOpen: boolean
  onCancel: () => void
  children: React.ReactNode
  closeButtonLabel: string
}

const ProductWishlistDialog: React.FC<ProductWishlistDialogProperties> = ({
  isOpen,
  onCancel,
  children,
  closeButtonLabel,
}) => {
  const dialogReference = useRef<HTMLDialogElement>(null)

  useEffect(() => {
    if (dialogReference.current) isOpen ? dialogReference.current.showModal() : dialogReference.current.close()
  }, [isOpen])

  return (
    <dialog ref={dialogReference} className="cmp-product-wishlist__dialog">
      <ButtonLink
        icon="ri-close-line"
        text={closeButtonLabel}
        onClick={onCancel}
        className="cmp-product-wishlist__dialog-close"
      />
      {children}
    </dialog>
  )
}

export default ProductWishlistDialog
