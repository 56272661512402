import React, { useState } from 'react'
import ButtonLink from '@components/ButtonLink'
import ProductWishlistDialog from '@components/Wishlist/ProductWishlistWidget/ProductWishlistDialog'
import { triggerAnalytics } from '@components/Wishlist/ProductWishlistWidget/ProductWishlistWidget'
import { getWishlist, getWishlistShareQueryParameter } from '@components/Wishlist/WishlistStorage'
import { cls } from '@utils/classnames'

interface ProductWishlistRemoveItemsDialogProperties {
  isOpen: boolean
  onCancel: () => void
  labels: ShareWishlistModalLabels
  productWishlistPageUrl: string
  closeButtonLabel: string
}

interface ShareWishlistModalLabels {
  shareLinkText: string
  copyLinkButtonLabel: string
  linkCopiedText: string
  shareLinkByEmailText: string
  openMailButtonLabel: string
  shareToSocialsText: string
  shareToSocialsButtonLabel: string
  shareInfoText: string
  shareTitle: string
  shareDescription: string
}

const ProductWishlistShare: React.FC<ProductWishlistRemoveItemsDialogProperties> = ({
  isOpen,
  onCancel,
  labels,
  productWishlistPageUrl,
  closeButtonLabel,
}) => {
  const [linkCopiedInClipboard, setLinkCopiedInClipboard] = useState(false)
  const wishlist = getWishlist()

  const generateWishlistLink = () => {
    const queryParameters = getWishlistShareQueryParameter()
    const encodedParameters = queryParameters ? encodeURIComponent(queryParameters) : null
    return encodedParameters ? `${productWishlistPageUrl}?wishlist=${encodedParameters}` : productWishlistPageUrl
  }

  function handleCopyLinkClick() {
    triggerAnalytics('share - copy link click', wishlist)
    navigator.clipboard
      .writeText(generateWishlistLink())
      .then(() => setLinkCopiedInClipboard(true))
      .then(() =>
        setTimeout(() => {
          setLinkCopiedInClipboard(false)
        }, 5000),
      )
  }

  function handleOpenMailClick() {
    triggerAnalytics('share - open email click', wishlist)
    const body = generateWishlistLink()
    window.location.href = `mailto:?subject=${encodeURIComponent(labels.shareTitle)}&body=${encodeURIComponent(body)}`
  }

  async function handleSocialMediaClick() {
    triggerAnalytics('share - social media click', wishlist)
    const shareData = {
      title: labels.shareTitle,
      text: labels.shareDescription,
      url: generateWishlistLink(),
    }
    try {
      await navigator.share(shareData)
    } catch (error) {
      window.console.log(`Error: ${error}`)
    }
  }
  return (
    <ProductWishlistDialog isOpen={isOpen} onCancel={onCancel} closeButtonLabel={closeButtonLabel}>
      <div className="cmp-product-wishlist__share">
        <div className="cmp-product-wishlist__share-category">
          <span className="cmp-product-wishlist__share-label">{labels.shareLinkText}</span>
          <ButtonLink
            text={linkCopiedInClipboard ? labels.linkCopiedText : labels.copyLinkButtonLabel}
            icon={linkCopiedInClipboard ? '' : 'ri-clipboard-line'}
            onClick={handleCopyLinkClick}
            className={cls({
              'cmp-product-wishlist__dialog-button': true,
              'cmp-product-wishlist__dialog-button--copied': linkCopiedInClipboard,
            })}
          />
        </div>
        <div className="cmp-product-wishlist__share-category">
          <span className="cmp-product-wishlist__share-label">{labels.shareLinkByEmailText}</span>
          <ButtonLink
            text={labels.openMailButtonLabel}
            icon="ri-mail-line"
            onClick={handleOpenMailClick}
            className="cmp-product-wishlist__dialog-button"
          />
        </div>
        <div className="cmp-product-wishlist__share-category">
          <span className="cmp-product-wishlist__share-label">{labels.shareToSocialsText}</span>
          <ButtonLink
            text={labels.shareToSocialsButtonLabel}
            icon="ri-share-line"
            onClick={handleSocialMediaClick}
            className="cmp-product-wishlist__dialog-button"
          />
        </div>
        <p className="cmp-product-wishlist__share-description">{labels.shareInfoText}</p>
      </div>
    </ProductWishlistDialog>
  )
}

export default ProductWishlistShare
