import { FC, useEffect, useMemo, useState } from 'react'
import { registerComponent } from 'react-register-dom'
import { WishlistEvent } from '@components/Wishlist/WishlistStorage'
import { cls } from '@utils/classnames'

interface ProductWishlistErrorBannerProperties {
  errorMessageTitle: string
  errorMessageDescription: string
  closeButtonAriaLabel: string
}

const ProductWishlistErrorBanner: FC<{ jsonData }> = ({ jsonData }) => {
  const {
    errorMessageTitle,
    errorMessageDescription,
    closeButtonAriaLabel = '',
  } = useMemo(() => JSON.parse(jsonData) as ProductWishlistErrorBannerProperties, [jsonData])

  const [isErrorVisible, setErrorVisible] = useState(false)

  useEffect(() => {
    window.addEventListener('wishlistMaximumReached', (event: any) => {
      setErrorVisible(true)
    })

    window.addEventListener('wishlistUpdated', (event: WishlistEvent) => {
      clearError()
    })
  }, [])

  const clearError = () => {
    setErrorVisible(false)
    window.dispatchEvent(new Event('wishlistErrorCleared'))
  }

  return (
    <div
      className={cls({
        'cmp-product-wishlist-errorBanner': true,
        'cmp-product-wishlist-errorBanner--visible': isErrorVisible,
      })}
    >
      <span className="cmp-product-wishlist-errorBanner__icon">
        <i className="ri-error-warning-line" />
      </span>
      <div className="cmp-product-wishlist-errorBanner__content">
        <p className="cmp-product-wishlist-errorBanner__title">{errorMessageTitle}</p>
        <p className="cmp-product-wishlist-errorBanner__description">{errorMessageDescription}</p>
      </div>
      <button
        className="cmp-product-wishlist-errorBanner__close"
        aria-label={closeButtonAriaLabel}
        onClick={clearError}
      >
        <span className="ri-close-line" />
      </button>
    </div>
  )
}

registerComponent('ProductWishlistErrorBanner', ProductWishlistErrorBanner)
export default ProductWishlistErrorBanner
