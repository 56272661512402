import { ProductPriceAndButtonConfig } from '@components/ProductDetailsPage/ProductPrice'

const WISHLIST_KEY = 'franke_wishlist'
const MAX_PRODUCT_COUNT = 50

export interface WishlistProduct {
  error?: boolean
  product?: {
    catalog: string
    locale: string
    sku: string
  }
  catalog: string
  locale: string
  price: ProductPriceAndButtonConfig
  sku: string
  thumbnail: {
    altText: string
    url: string
  }
  title: string
  link: string
  brandCodes: string
}

function loadFromStorage(): WishlistProduct[] {
  const storage = localStorage.getItem(WISHLIST_KEY)

  if (storage == null) {
    return []
  }

  return JSON.parse(storage) || []
}

function saveToStorage(list: WishlistProduct[]) {
  localStorage.setItem(WISHLIST_KEY, JSON.stringify(list))
}

export class WishlistEvent extends Event {
  data: WishlistProduct[]
}

/**
 * call this to add a product to the wishlist
 * @param product product info to be added
 * @returns the updated list
 */
export function addProductToWishlist(product: WishlistProduct): WishlistProduct[] {
  const list = loadFromStorage()

  if (list.length >= MAX_PRODUCT_COUNT) {
    const event = new WishlistEvent('wishlistMaximumReached')
    event.data = [product]
    window.dispatchEvent(event)

    return list
  }

  list.push(product)
  saveToStorage(list)

  const event = new WishlistEvent('wishlistUpdated')
  event.data = list
  window.dispatchEvent(event)

  return list
}

/**
 * call this to retrieve the wishlist
 * @returns the list of products in the wishlist
 */
export function getWishlist(): WishlistProduct[] {
  return loadFromStorage()
}

/**
 * call this to remove a single item from the wishlist by index
 * @param productIndex the index of the element to remove
 * @returns the updated list
 */
export function removeProductFromWishlist(productIndex: number): WishlistProduct[] {
  const list = loadFromStorage()

  if (!list[productIndex]) {
    throw new Error(`Product at index ${productIndex} was not found in the list.`)
  }

  list.splice(productIndex, 1)
  saveToStorage(list)

  const event = new WishlistEvent('wishlistUpdated')
  event.data = list
  window.dispatchEvent(event)

  return list
}

/**
 * removes all elements from the wishlist returning a new empty list
 * @returns an empty wishlist
 */
export function clearWishlist(): WishlistProduct[] {
  const list = []
  saveToStorage(list)

  const event = new WishlistEvent('wishlistUpdated')
  event.data = list
  window.dispatchEvent(event)

  return list
}

/**
 * returns the query parameter value for the wishlist shared page, non encoded. add it to
 * the parameter it needs to be in when composing the url for the wishlist and remember to encode it
 * with `encodeUriComponent()` accordingly
 * @returns {null | string} a non-encoded string if the list is not empty, null otherwise
 */
export function getWishlistShareQueryParameter() {
  const list = loadFromStorage()
  if (list.length <= 0) {
    return null
  }

  return list.map((p) => [p.catalog, p.locale, p.sku, p.brandCodes].join(':')).join(',')
}

/**
 * checks wether or not the product is present in the checklist
 * @param sku product code
 * @returns {boolean} true when the product is in the wishlist
 */
export function isProductInWishlist(sku: string) {
  const list = loadFromStorage()

  return list.some((product) => product.sku === sku)
}
