import React from 'react'
import { registerComponent } from 'react-register-dom'
import ButtonLink from '@components/ButtonLink'
import { ProductPriceAndButtonConfig as OriginalProductPriceAndButtonConfig } from '@components/ProductDetailsPage/ProductPrice'
import { triggerAnalytics } from '@components/Wishlist/ProductWishlistWidget/ProductWishlistWidget'
import { getWishlist, removeProductFromWishlist } from '@components/Wishlist/WishlistStorage'

interface ProductWishlistItemProperties {
  productWishlistItem: ProductWishlistItem
  articleNumberLabel: string
  priceVatLabel: string
  productButtonLabel: string
  productSheetLabel: string
  baseProductSheetProxyUrl: string
  removeProductLabel: string
  errorMessageLabel: string
  baseProductUrl: string
  isSharedWishlist?: boolean
}

interface ProductPriceAndButtonConfig extends OriginalProductPriceAndButtonConfig {
  formattedValue?: string
  buyProductLink?: string
}

interface CompleteProductWishlistItem {
  catalog: string
  locale: string
  price: ProductPriceAndButtonConfig
  sku: string
  thumbnail: {
    altText: string
    url: string
  }
  title: string
}

interface ErrorProductWishlistItem {
  error: boolean
  product: {
    catalog: string
    locale: string
    sku: string
  }
}

type ProductWishlistItem = CompleteProductWishlistItem | ErrorProductWishlistItem

function isCompleteProductWishlistItem(item: ProductWishlistItem): item is CompleteProductWishlistItem {
  return !item['error']
}

const ProductWishlistItem: React.FC<ProductWishlistItemProperties> = ({
  productWishlistItem,
  baseProductSheetProxyUrl,
  productSheetLabel,
  productButtonLabel,
  articleNumberLabel,
  priceVatLabel,
  removeProductLabel,
  isSharedWishlist,
  errorMessageLabel,
  baseProductUrl,
}) => {
  function handleRemoveProduct() {
    if (isCompleteProductWishlistItem(productWishlistItem)) {
      const productIndex = wishlist.findIndex((wishlistItem) => wishlistItem.sku === productWishlistItem.sku)
      if (productIndex !== -1) removeProductFromWishlist(productIndex)
    }
  }

  const wishlist = getWishlist()

  if (!isCompleteProductWishlistItem(productWishlistItem)) {
    return (
      <div className="cmp-product-wishlist__product-unavailable">
        <div className="cmp-product-wishlist__product-info">
          <p className="cmp-product-wishlist__product-label">{articleNumberLabel}</p>
          <p className="cmp-product-wishlist__product-sku">{productWishlistItem.product.sku}</p>
        </div>
        <span className="cmp-product-wishlist__product-unavailable-description">
          <i className="ri-close-circle-line cmp-product-wishlist__product-unavailable-description--hasIcon"></i>
          {errorMessageLabel}
        </span>
      </div>
    )
  }

  const downloadUrl = baseProductSheetProxyUrl
    .replace(':productCatalog', productWishlistItem.catalog)
    .replace(':productCode', productWishlistItem.sku)
    .replace(':productLocale', productWishlistItem.locale)

  return (
    <div className="cmp-product-wishlist__product">
      <div className="cmp-product-wishlist__product-thumbnail">
        <img src={productWishlistItem.thumbnail.url} alt={productWishlistItem.thumbnail.altText} />
      </div>
      <div className="cmp-product-wishlist__product-content">
        <div className="cmp-product-wishlist__product-heading">
          <p className="cmp-product-wishlist__product-title">{productWishlistItem.title}</p>
          {!isSharedWishlist && (
            <ButtonLink
              icon="ri-delete-bin-line"
              className="cmp-product-wishlist__product-delete"
              onClick={handleRemoveProduct}
              ariaLabel={removeProductLabel}
            />
          )}
        </div>
        <div className="cmp-product-wishlist__product-description">
          <div className="cmp-product-wishlist__product-info">
            <p className="cmp-product-wishlist__product-label">{articleNumberLabel}</p>
            <p className="cmp-product-wishlist__product-sku">{productWishlistItem.sku}</p>
          </div>
          {(productWishlistItem.price?.price?.formattedValue || productWishlistItem.price?.formattedValue) && (
            <div className="cmp-product-wishlist__product-price">
              <p className="cmp-product-wishlist__product-price-value">
                {productWishlistItem.price.price?.formattedValue || productWishlistItem.price.formattedValue}
              </p>
              <span className="cmp-product-wishlist__product-price-vat">{priceVatLabel}</span>
            </div>
          )}
        </div>
        <div className="cmp-product-wishlist__product-actions">
          <a
            href={downloadUrl}
            className="cmp-product-wishlist__product-download"
            target="_blank"
            onClick={() => triggerAnalytics('wishlist product download click', productWishlistItem)}
          >
            {productSheetLabel} <i className="ri-download-line cmp-product-wishlist__product-icon" />
          </a>
          {baseProductUrl && (
            <a
              href={baseProductUrl.replace(':productId', productWishlistItem.sku)}
              className="cmp-product-wishlist__product-link"
            >
              {productButtonLabel} <i className="ri-arrow-right-line" />
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

registerComponent('ProductWishlistWidget', ProductWishlistItem)
export default ProductWishlistItem
