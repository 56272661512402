import React, { useEffect, useState } from 'react'
import { registerComponent } from 'react-register-dom'
import ButtonLink from '@components/ButtonLink'
import Logo, { LogoProperties } from '@components/Header/Logo'
import ProductWishlistItem from '@components/Wishlist/ProductWishlistWidget/ProductWishlistItem'
import { ProductWishlistConfig } from '@components/Wishlist/ProductWishlistWidget/ProductWishlistWidget'
import { getProductsForWishlist } from '@components/Wishlist/SharedWishlistStorage'
import { WishlistProduct } from '@components/Wishlist/WishlistStorage'

const SharedProductWishlist: React.FC = () => {
  const [wishlist, setWishlist] = useState<WishlistProduct[] | undefined>()
  const [config, setConfig] = useState<ProductWishlistConfig | undefined>()
  const [logo, setLogo] = useState<LogoProperties | undefined>()

  useEffect(() => {
    const loadConfigAndData = async () => {
      const body = document.querySelector('body') as HTMLElement | null
      body?.classList.add('wishlist-print', 'wishlist-print--shared')

      const headerElement = document.querySelector('.cmp-header') as HTMLElement | null
      if (!headerElement) return

      const jsonData = headerElement.dataset.cmpDataJson
      if (!jsonData) return

      const parsedData = JSON.parse(jsonData)
      const { logo, productwishlistwidget } = parsedData[':items']
      if (!productwishlistwidget) return

      setConfig(productwishlistwidget)
      setLogo(logo)
      const data: any = await getProductsForWishlist(productwishlistwidget.productWishlistEndpointUrl)
      setWishlist(data.products)
    }

    loadConfigAndData()
  }, [])

  return (
    <div className="cmp-product-shared-wishlist">
      <div className="cmp-product-shared-wishlist__header">
        <h3 className="cmp-product-wishlist__title">{config?.overlayTitleLabel}</h3>
        {logo && (
          <Logo
            backgroundImage={logo.backgroundImage}
            foregroundImage={logo.foregroundImage}
            altText={logo.altText}
            logoLink={logo.logoLink}
          />
        )}
      </div>
      {wishlist && config ? (
        <div className="cmp-product-wishlist">
          {wishlist.length > 0 && (
            <div className="cmp-product-wishlist__actions">
              <p className="cmp-product-wishlist__sum">
                <span className="cmp-product-wishlist__sum--number">{wishlist.length}</span>{' '}
                {wishlist.length > 1 ? config.pluralProductsLabel : config.singleProductLabel}
              </p>
              <ButtonLink
                className="cmp-product-wishlist__button"
                text={config.saveAsPdfButtonLabel}
                icon="ri-download-line"
                onClick={() => window.print()}
              />
            </div>
          )}
          <div className="cmp-product-wishlist__products">
            {wishlist.map((wishlistProduct) => (
              <ProductWishlistItem
                productWishlistItem={wishlistProduct}
                productButtonLabel={config.productButtonLabel}
                productSheetLabel={config.productSheetLabel}
                priceVatLabel={config.priceVatLabel}
                articleNumberLabel={config.articleNumberLabel}
                key={wishlistProduct.sku}
                baseProductSheetProxyUrl={config.baseProductSheetProxyUrl}
                removeProductLabel={config.removeProductButtonLabel}
                isSharedWishlist={true}
                errorMessageLabel={config.errorMessageLabel}
                baseProductUrl={config.baseProductUrl}
              />
            ))}
          </div>
        </div>
      ) : (
        <p>Loading wishlist...</p>
      )}
    </div>
  )
}

registerComponent('SharedProductWishlist', SharedProductWishlist)
export default SharedProductWishlist
