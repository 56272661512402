import * as React from 'react'
import { searchUrlParameters } from '@components/CoffeeMachineFinder/constants'
import { PDF_CMF_KEY } from '@constants/localStorage'
import { optionalQuerySymbol } from '@hooks/use-state-in-url'
import { useLocalStorage } from '@hooks/useLocalStorage'
import { clearUrlParametersForPdfExport } from '@utils/clearUrlParametersForPdfExport'
import { getQueryParameters } from '@utils/url'

import { QuestionConfig, QuestionnaireConfig } from './CoffeeMachineFinderTypes'

interface SelectedOptionsMappingProperties {
  [paramName: typeof searchUrlParameters.ANSWERS | typeof searchUrlParameters.WEIGHTS]: {
    selectedOptions: { [key: string]: boolean }
    setOptions: React.Dispatch<
      React.SetStateAction<{
        [key: string]: boolean
      }>
    >
  }
}
export interface CoffeeMachineFinderQueryParameters {
  [key: string]: string | string[]
  step: string
  answers: string[]
  results: string
  tab: string
}

function selectedArrayToObject(commaSeparatedArray?: string[] | string): {
  [key: string]: boolean
} {
  if (!commaSeparatedArray) {
    return {}
  }

  // when type is of string, it means commaSeparatedArray was selected by the user
  if (typeof commaSeparatedArray === 'string') {
    return { [commaSeparatedArray]: true }
  }
  const selectedMap: { [optionId: string]: boolean } = {}

  for (const key of commaSeparatedArray) {
    if (key?.length > 0) {
      selectedMap[key] = true
    }
  }

  return selectedMap
}

/**
 * returns the index of the highest step that can be validly displayed
 *
 * @param selectedOptionsMapping map of currently selected items with setter function
 * @param parsedConfig the questionnaire configuration object
 * @returns the index of the last question that can be displayed
 */
function lastDisplayableStep(
  parsedConfig: QuestionnaireConfig,
  selectedOptionsMapping: SelectedOptionsMappingProperties,
) {
  // if we're on step k, with answer x, and anwer x's next step is k + l, with l > 1
  // we need to provide k + l as answer
  let index = 0

  while (index < parsedConfig.length) {
    const step = parsedConfig[index]
    const options = step.options.find((opt) => selectedOptionsMapping[step.paramName].selectedOptions[opt.id])
    const stepCompleted = isQuestionCompleted(step, selectedOptionsMapping[step.paramName].selectedOptions)

    if (!stepCompleted) {
      return index
    }

    // this will skip unnecessary questions
    index = options?.nextStepIndex ?? index + 1
  }

  // if the exec leaves the for, every step is valid
  return parsedConfig.length - 1
}

function getInitialCurrentStep(firstUnansweredStep: number) {
  const { step } = getQueryParameters<CoffeeMachineFinderQueryParameters>()
  const stepFromQueryParameters = Number.parseInt(step || '1') - 1

  return Math.min(stepFromQueryParameters || 0, firstUnansweredStep)
}

function isQuestionCompleted(question: QuestionConfig, selectedOptions: { [optionId: string]: boolean }) {
  if (question.canBeEmpty) {
    return true
  }

  return question.options.some((opt) => selectedOptions[opt.id])
}

export function syncCoffeeMachineFinderQueryParameter(parameterName: string, value: string | string[]) {
  const parameters = new URLSearchParams(location.search)

  if (typeof value === 'string') {
    parameters.set(parameterName, value)
  } else {
    parameters.delete(parameterName)

    for (const key of value) {
      parameters.append(parameterName, key)
    }
  }

  const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}${optionalQuerySymbol(
    parameters.toString(),
  )}`

  window.history.replaceState({ path: newUrl }, '', newUrl)
}

export function useStepperNavigationLogic(
  setCurrentStep: React.Dispatch<React.SetStateAction<number>>,
  currentStep: number,
  config: QuestionnaireConfig,
  selectedOptionsMapping: SelectedOptionsMappingProperties,
  setShowResults: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const goToStep = React.useCallback(
    (targetStep: number) => {
      // the step displayed in the url is 1-based, just to make it look better
      syncCoffeeMachineFinderQueryParameter('step', (targetStep + 1).toString())
      setCurrentStep(targetStep)
      window.scrollTo({ top: 0, left: 0 })
    },
    [setCurrentStep],
  )

  const goBack = React.useCallback(() => {
    const currentStepConfig = config[currentStep]
    // we might have skipped some steps..
    // we can know if that's the case looking at previous questions.
    // the first question that is completed going backward is the last non-skipped one.
    let lastStep = 0

    for (let index = currentStep - 1; index >= 0; index--) {
      if (isQuestionCompleted(config[index], selectedOptionsMapping[currentStepConfig.paramName].selectedOptions)) {
        lastStep = index

        break
      }
    }

    goToStep(lastStep)
  }, [currentStep, config, selectedOptionsMapping, goToStep])

  // eslint-disable-next-line sonarjs/cognitive-complexity
  const goForward = React.useCallback(() => {
    const last = config.length - 1 === currentStep
    const currentStepConfig = config[currentStep]
    const selectedOptions = selectedOptionsMapping[currentStepConfig.paramName].selectedOptions
    // we assume there's only 1 selected option in the questions with nextStepIndex set
    const currentOption = currentStepConfig.options.find((opt) => selectedOptions[opt.id])

    if (!currentOption && !currentStepConfig.canBeEmpty) {
      // user still needs to answer this
      return
    }

    if (currentOption?.nextStepIndex) {
      // clean all the answers of the skipped questions
      const cleanupMap: { [optionId: string]: boolean } = {}

      for (let index = currentStep + 1; index < currentOption.nextStepIndex; index++) {
        const answerForSkippedQuestion = config[index].options.find((opt) => selectedOptions[opt.id])

        if (answerForSkippedQuestion) {
          cleanupMap[answerForSkippedQuestion.id] = false
        }
      }

      selectedOptionsMapping[currentStepConfig.paramName].setOptions((old) => ({
        ...old,
        ...cleanupMap,
      }))
    }

    if (!last) {
      goToStep(currentOption?.nextStepIndex ?? currentStep + 1)
    } else {
      syncCoffeeMachineFinderQueryParameter('results', 'true')
      setShowResults(true)
    }
  }, [config, currentStep, selectedOptionsMapping, goToStep, setShowResults])

  const tryGoToTargetStep = React.useCallback(
    (stepIndex: number) => {
      const currentStepConfig = config[currentStep]
      // we can only directly navigate to steps that have already an answer
      // the others were skipped or are future ones.
      const targetStep = config[stepIndex]
      const hasAnswer = isQuestionCompleted(
        targetStep,
        selectedOptionsMapping[currentStepConfig.paramName].selectedOptions,
      )

      if (hasAnswer) {
        goToStep(stepIndex)
      }
    },
    [config, selectedOptionsMapping, goToStep],
  )
  const currentStepConfig = config[currentStep]
  const currentOption = currentStepConfig.options.find(
    (opt) => selectedOptionsMapping[currentStepConfig.paramName].selectedOptions[opt.id],
  )
  const canGoForward = Boolean(currentOption) || currentStepConfig.canBeEmpty

  return {
    canGoForward,
    goBack,
    goForward,
    goToStep: tryGoToTargetStep,
  }
}

export function useToggleOption(
  config: QuestionnaireConfig,
  currentStep: number,
  selectedOptionsMapping: SelectedOptionsMappingProperties,
) {
  return React.useCallback(
    (stepOptionId: string) => {
      const stepConfig = config[currentStep]

      selectedOptionsMapping[stepConfig.paramName].setOptions((previousSelectedOptions) => {
        // get a map of current's step ids as false when to reset other options (single-selection vs multiple-selection)
        const existingOptions = Object.fromEntries(
          stepConfig.options.map((option) => {
            const selectedOptionValue = selectedOptionsMapping[stepConfig.paramName].selectedOptions[option.id]
            return [option.id, stepConfig.canHaveMultipleAnswers && selectedOptionValue ? selectedOptionValue : false]
          }),
        )

        const newSelectedOptions = {
          ...previousSelectedOptions,
          ...existingOptions,
          [stepOptionId]: !previousSelectedOptions[stepOptionId],
        }

        syncCoffeeMachineFinderQueryParameter(
          stepConfig.paramName,
          Object.keys(newSelectedOptions).filter((k) => newSelectedOptions[k]),
        )

        return newSelectedOptions
      })
    },
    [currentStep, config, selectedOptionsMapping],
  )
}

export function usePrepareLocalStorageForPdf({
  questionnaire,
  pdfExportUrl,
  allSelectedOptions,
  showResults,
}: {
  questionnaire: QuestionnaireConfig
  pdfExportUrl: string
  allSelectedOptions: Record<string, boolean>
  showResults: boolean
}) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setValue] = useLocalStorage({
    key: PDF_CMF_KEY,
    defaultValue: '',
  })
  const initialStepIndexes = {}
  const stepAndTagIndexes = React.useMemo(() => {
    // eslint-disable-next-line unicorn/no-array-reduce, unicorn/prefer-object-from-entries
    return questionnaire.reduce((stepAccumulator, { title, options }) => {
      // eslint-disable-next-line unicorn/no-array-reduce, unicorn/prefer-object-from-entries
      const optionDetails = options.reduce((optionAccumulator, option) => {
        return {
          ...optionAccumulator,
          [option.id]: { title: option.title, subTitle: option.subTitle },
        }
      }, {})

      return {
        ...stepAccumulator,
        [title]: optionDetails,
      }
    }, {})
  }, [questionnaire])

  for (const stepKey of Object.keys(stepAndTagIndexes)) {
    initialStepIndexes[stepKey] = []
  }

  const prepareSelectedOptionsForPDF = React.useCallback(() => {
    let stepIndexes = {}
    const filteredUserSelectedOptions = Object.entries(allSelectedOptions).filter(
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      ([_, value]) => Boolean(value),
    )
    const selectedOptionsTags = Object.fromEntries(filteredUserSelectedOptions)
    for (const tagKey of Object.keys(selectedOptionsTags)) {
      for (const stepKey of Object.keys(stepAndTagIndexes)) {
        if (stepAndTagIndexes[stepKey][tagKey]) {
          stepIndexes = {
            ...stepIndexes,
            [stepKey]: [
              // previous content for this stepKey
              ...(stepIndexes?.[stepKey] || []),
              { tag: tagKey, ...stepAndTagIndexes[stepKey][tagKey] },
            ],
          }
        }
      }
    }

    setValue({
      // merge and we should get the proper data structure with the skipped steps
      selectedOptions: { ...initialStepIndexes, ...stepIndexes },
      resultPageUrl: window.location.href,
      // we don't need to save the step, results and author mode
      urlParams: clearUrlParametersForPdfExport(window.location.search),
      pdfExportUrl,
    })
  }, [allSelectedOptions])

  React.useEffect(() => {
    if (showResults) {
      prepareSelectedOptionsForPDF()
    }
  }, [showResults])
}

export function useStepperState(config: QuestionnaireConfig) {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, setValue] = useLocalStorage({
    key: PDF_CMF_KEY,
    defaultValue: '',
  })
  const { answers, weights, results } = getQueryParameters<CoffeeMachineFinderQueryParameters>()
  const [showResults, setShowResults] = React.useState(results === 'true')
  const [selectedAnswersOptions, setSelectedAnswersOptions] = React.useState(selectedArrayToObject(answers))
  const [selectedWeightsOptions, setSelectedWeightsOptions] = React.useState(selectedArrayToObject(weights))
  const selectedOptionsMapping: SelectedOptionsMappingProperties = React.useMemo(
    () => ({
      [searchUrlParameters.ANSWERS]: {
        selectedOptions: selectedAnswersOptions,
        setOptions: setSelectedAnswersOptions,
      },
      [searchUrlParameters.WEIGHTS]: {
        selectedOptions: selectedWeightsOptions,
        setOptions: setSelectedWeightsOptions,
      },
    }),
    [selectedAnswersOptions, setSelectedAnswersOptions, selectedWeightsOptions, setSelectedWeightsOptions],
  )
  const firstStepToFill = React.useMemo(
    () => lastDisplayableStep(config, selectedOptionsMapping),
    [selectedOptionsMapping, config],
  )
  const [currentStep, setCurrentStep] = React.useState(getInitialCurrentStep(firstStepToFill))
  const { goBack, goForward, goToStep, canGoForward } = useStepperNavigationLogic(
    setCurrentStep,
    currentStep,
    config,
    selectedOptionsMapping,
    setShowResults,
  )
  const toggleOption = useToggleOption(config, currentStep, selectedOptionsMapping)
  const resetQuestionnaire = () => {
    setValue('')
    setSelectedAnswersOptions({})
    setSelectedWeightsOptions({})
    setCurrentStep(0)
    window.scrollTo({ top: 0, left: 0 })
    setShowResults(false)
    const newUrl = `${window.location.protocol}//${window.location.host}${window.location.pathname}`
    window.history.replaceState({ path: newUrl }, '', newUrl)
  }

  return {
    selectedAnswersOptions,
    selectedWeightsOptions,
    currentStep,
    showResults,
    canGoForward,
    goBack,
    goForward,
    goToStep,
    toggleOption,
    resetQuestionnaire,
  }
}
