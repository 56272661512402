import { useCallback, useState } from 'react'

export function useStateInUrl(initialState: string, parameterName: string) {
  const initialQuery = new URLSearchParams(location.search)
  const initialValueInUrl = initialQuery.get(parameterName)
  const [state, setState] = useState(initialValueInUrl || initialState)

  const onChange = useCallback(
    (functionOrValue: string | ((old: string) => string)) => {
      setState((old) => {
        const newValue = functionOrValue instanceof Function ? functionOrValue(old) : functionOrValue

        const query = new URLSearchParams(location.search)

        if (newValue.toString().length > 0) {
          query.set(parameterName, newValue)
        } else {
          query.delete(parameterName)
        }

        const newUrl = `${window.location.protocol}//${window.location.host}${
          window.location.pathname
        }${optionalQuerySymbol(query.toString())}`
        window.history.replaceState({ path: newUrl }, '', newUrl)

        return newValue
      })
    },
    [parameterName, setState],
  )

  return [state, onChange] as const
}

export function optionalQuerySymbol(query: string) {
  return `${query.length > 0 ? '?' : ''}${query}`
}
