import React from 'react'
import { registerComponent } from 'react-register-dom'
import { cls } from '@utils/classnames'

interface ProductPriceConfig {
  currencyIso: string
  formattedValue: string
  value: number
  buyProductLink: string
}

export interface ProductPriceAndButtonConfig {
  priceButtonLabel: string
  price: ProductPriceConfig
  priceVatLabel?: string
  productAvailable: boolean
}

interface ProductPriceAndBuyProperties {
  jsonData: string
}

const ProductPrice: React.FC<ProductPriceAndBuyProperties> = ({ jsonData }) => {
  const config: ProductPriceAndButtonConfig = React.useMemo(() => {
    return JSON.parse(jsonData)
  }, [jsonData])

  return (
    <div
      className={cls({
        'cmp-product-price-and-buy-container': true,
        'cmp-product-price-and-buy-container--is-out-of-stock': !config.productAvailable,
      })}
    >
      {config?.price?.formattedValue ? <div className="cmp-product__price">{config?.price?.formattedValue}</div> : null}
      {config?.priceVatLabel ? <small className="cmp-product__vat">{config?.priceVatLabel}</small> : null}
      {config.price?.buyProductLink && config.productAvailable ? (
        <>
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a
            onClick={() => {
              window.adobeDataLayer?.push({
                event: 'shop locator detail click',
              })
            }}
            className="cmp-product__buy"
            href={config.price.buyProductLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className="ri-shopping-cart-line cmp-product__buy-icon"></i>
            <span className="cmp-product__buy-label"> {config?.priceButtonLabel}</span>
          </a>
        </>
      ) : null}
    </div>
  )
}

export default ProductPrice

registerComponent('ProductPrice', ProductPrice)
