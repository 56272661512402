import React, { useEffect, useState } from 'react'
import { registerComponent } from 'react-register-dom'
import ButtonLink from '@components/ButtonLink'
import ProductWishlistItem from '@components/Wishlist/ProductWishlistWidget/ProductWishlistItem'
import ProductWishlistRemoveItems from '@components/Wishlist/ProductWishlistWidget/ProductWishlistRemoveItems'
import ProductWishlistShare from '@components/Wishlist/ProductWishlistWidget/ProductWishlistShare'

import { clearWishlist, getWishlist, WishlistEvent, WishlistProduct } from '../WishlistStorage'

interface ProductWishlistProperties {
  config: ProductWishlistConfig
}
export interface ProductWishlistConfig {
  articleNumberLabel: string
  baseProductSheetProxyUrl: string
  closeButtonLabel: string
  errorMessageLabel: string
  overlayDescriptionLabel: string
  overlayTitleLabel: string
  pluralProductsLabel: string
  priceVatLabel: string
  productButtonLabel: string
  productSheetLabel: string
  productWishlistPageUrl: string
  removeProductButtonLabel: string
  removeAllButtonLabel: string
  removeAllModalLabels: {
    removeAllDescription: string
    cancelButtonLabel: string
    confirmButtonLabel: string
  }
  saveAsPdfButtonLabel: string
  shareButtonLabel: string
  shareModalLabels: {
    shareLinkText: string
    copyLinkButtonLabel: string
    linkCopiedText: string
    shareLinkByEmailText: string
    openMailButtonLabel: string
    shareToSocialsText: string
    shareToSocialsButtonLabel: string
    shareInfoText: string
    shareTitle: string
    shareDescription: string
  }
  singleProductLabel: string
  productWishlistEndpointUrl: string
  errorEmptyProductsLabel: string
  baseProductUrl: string
}

export function triggerAnalytics(action, product) {
  window.adobeDataLayer?.push({
    event: action,
    product,
  })
}

const ProductWishlistWidget: React.FC<ProductWishlistProperties> = ({ config }) => {
  const [wishlist, setWishlist] = useState<WishlistProduct[]>(getWishlist())
  const [isRemoveAllProductsModalOpen, setRemoveAllProductsModalOpen] = useState(false)
  const [isShareWishlistModalOpen, setShareWishlistModalOpen] = useState(false)

  useEffect(() => {
    window.addEventListener('wishlistUpdated', (event: WishlistEvent) => {
      setWishlist(event.data)
    })

    const body = document.querySelector('body') as HTMLElement | null
    body?.classList.add('wishlist-print')
  }, [])

  function handleSaveAsPdf() {
    triggerAnalytics('save wishlist as pdf click', wishlist)
    window.print()
  }

  function handleRemoveAllProducts() {
    setRemoveAllProductsModalOpen(true)
  }

  function confirmRemoveAllProducts() {
    triggerAnalytics('remove all products from wishlist click', wishlist)
    clearWishlist()
    setRemoveAllProductsModalOpen(false)
  }

  function cancelRemoveAllProducts() {
    setRemoveAllProductsModalOpen(false)
  }

  function handleShareWishlist() {
    setShareWishlistModalOpen(true)
  }

  function cancelShareWishlist() {
    setShareWishlistModalOpen(false)
  }

  return (
    <div className="cmp-product-wishlist">
      <h3 className="cmp-product-wishlist__title">{config.overlayTitleLabel}</h3>
      <p className="cmp-product-wishlist__description">{config.overlayDescriptionLabel}</p>
      {wishlist.length > 0 && (
        <div className="cmp-product-wishlist__actions">
          <p className="cmp-product-wishlist__sum">
            <span className="cmp-product-wishlist__sum--number">{wishlist.length}</span>{' '}
            {wishlist.length > 1 ? config.pluralProductsLabel : config.singleProductLabel}
          </p>
          <ButtonLink
            className="cmp-product-wishlist__button cmp-product-wishlist__button--remove"
            text={config.removeAllButtonLabel}
            onClick={handleRemoveAllProducts}
          />
          <ButtonLink
            className="cmp-product-wishlist__button"
            text={config.saveAsPdfButtonLabel}
            icon="ri-download-line"
            onClick={handleSaveAsPdf}
          />
          <ButtonLink
            className="cmp-product-wishlist__button cmp-product-wishlist__button--inverted"
            text={config.shareButtonLabel}
            icon="ri-share-line"
            onClick={handleShareWishlist}
          />
        </div>
      )}
      <div className="cmp-product-wishlist__products">
        {wishlist.map((wishlistProduct) => (
          <ProductWishlistItem
            productWishlistItem={wishlistProduct}
            productButtonLabel={config.productButtonLabel}
            productSheetLabel={config.productSheetLabel}
            priceVatLabel={config.priceVatLabel}
            articleNumberLabel={config.articleNumberLabel}
            key={wishlistProduct.sku}
            baseProductSheetProxyUrl={config.baseProductSheetProxyUrl}
            removeProductLabel={config.removeProductButtonLabel}
            isSharedWishlist={false}
            errorMessageLabel={config.errorMessageLabel}
            baseProductUrl={config.baseProductUrl}
          />
        ))}
      </div>
      {wishlist.length === 0 && <p className="cmp-product-wishlist--isEmpty">{config.errorEmptyProductsLabel}</p>}

      <ProductWishlistRemoveItems
        labels={config.removeAllModalLabels}
        closeButtonLabel={config.closeButtonLabel}
        isOpen={isRemoveAllProductsModalOpen}
        onConfirm={confirmRemoveAllProducts}
        onCancel={cancelRemoveAllProducts}
      />
      <ProductWishlistShare
        isOpen={isShareWishlistModalOpen}
        closeButtonLabel={config.closeButtonLabel}
        onCancel={cancelShareWishlist}
        labels={config.shareModalLabels}
        productWishlistPageUrl={config.productWishlistPageUrl}
      />
    </div>
  )
}

registerComponent('ProductWishlistWidget', ProductWishlistWidget)
export default ProductWishlistWidget
