import { createContext, MutableRefObject } from 'react'

export interface NavigationItemTeaser {
  description: string
  id: string
  illustrationLabel: string
  link: string
  linkLabel: string
  teaserImage: {
    altText: string
    htmlAttributes: string
    originalImageUrl: string
  }
}

// TODO: cleanup this mess
const defaultContext = {
  isHeaderOpened: false,
  headerDropdownContent: 'navigation',
  navigationTeaserData: {} as { teaser?: NavigationItemTeaser },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setIsHeaderOpened: (openHeader: boolean): void => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  setIsSubNavigationOpenOnMobile: (openSubNavigation: boolean): void => {},
  isSubNavigationOpenOnMobile: false,
  setNavigationTeaserData: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    NavigationItem: { teaser?: NavigationItemTeaser },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ): void => {},
  setHeaderDropdownContent: (
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    content: 'navigation' | 'languageSwitcher' | 'search' | 'productWishlistWidget',
    // eslint-disable-next-line @typescript-eslint/no-empty-function
  ) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setTopNavigationData: (data: any): void => {},
  headerDataJson: {},
  teaserContainerReference: { current: null } as MutableRefObject<null | HTMLDivElement>,
  teaserContentReference: { current: null } as MutableRefObject<null | HTMLDivElement>,
  topNavigationData: null as Record<string, unknown> | null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  waitForTransition: (callback, reference) => {},
  currentSectionId: null as string | null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setCurrentSectionId: (id: null | string): void => {},
}

export const HeaderContext = createContext(defaultContext)
