import { WishlistProduct } from './WishlistStorage'

function getWishlistFromPageUrl(parameterName: string) {
  const parameters = new URLSearchParams(location.search)
  return parameters.get(parameterName)
}

/**
 * attempts to read the wishlist from the page url, throwing an error if not found;
 * then it downloads the wishlist and returns it, throws in case of problems with the call.
 * @param baseUrl the endpoint to be used to retrieve the data
 * @returns The wishlist products
 */
export async function getProductsForWishlist(baseUrl: string): Promise<WishlistProduct[]> {
  const wishlist = getWishlistFromPageUrl('wishlist')
  if (!wishlist) {
    throw new Error("Wishlist information not found in the current page URL, check for a parameter called 'wishlist'")
  }

  const queryIndex = baseUrl.indexOf('?')
  const basePath = queryIndex > -1 ? baseUrl.slice(0, queryIndex) : baseUrl
  const existingQuery = queryIndex > -1 ? baseUrl.slice(queryIndex + 1) : ''
  const parameters = new URLSearchParams(existingQuery)
  parameters.append('wishlist', wishlist)

  const queryString = parameters.toString()
  const fullUrl = queryString ? `${basePath}?${queryString}` : basePath

  const result = await fetch(fullUrl)
  if (result.status < 400) {
    return await result.json()
  } else {
    throw new Error(`Could not fetch the wishlist ${result.status} ${result.statusText}`)
  }
}
