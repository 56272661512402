import React from 'react'
import ProductWishlistDialog from '@components/Wishlist/ProductWishlistWidget/ProductWishlistDialog'

interface ProductWishlistRemoveItemsDialogProperties {
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
  labels: RemoveAllModalLabels
  closeButtonLabel: string
}

interface RemoveAllModalLabels {
  removeAllDescription: string
  cancelButtonLabel: string
  confirmButtonLabel: string
}

const ProductWishlistRemoveItems: React.FC<ProductWishlistRemoveItemsDialogProperties> = ({
  isOpen,
  onConfirm,
  onCancel,
  labels,
  closeButtonLabel,
}) => {
  return (
    <ProductWishlistDialog isOpen={isOpen} onCancel={onCancel} closeButtonLabel={closeButtonLabel}>
      <p className="cmp-product-wishlist__dialog-description">{labels.removeAllDescription}</p>
      <div className="cmp-product-wishlist__dialog-actions">
        <button className="cmp-product-wishlist__dialog-button" onClick={onCancel}>
          {labels.cancelButtonLabel}
        </button>
        <button
          className="cmp-product-wishlist__dialog-button cmp-product-wishlist__dialog-button--positive"
          onClick={onConfirm}
        >
          {labels.confirmButtonLabel}
        </button>
      </div>
    </ProductWishlistDialog>
  )
}

export default ProductWishlistRemoveItems
