import React, { useMemo, useRef, useState } from 'react'
import { CountryLanguageSwitcher } from '@components/Header/LanguageSwitcher/LanguageSwitcherCountry'
import { LogoProperties } from '@components/Header/Logo'
import {
  NavigationSectionModel,
  NavigationTeaser as NavigationTeaserModel,
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
} from '@components/Header/Navigation/navigation.d.ts'
import { SearchConfig } from '@components/Header/Search'
import { ProductWishlistConfig } from '@components/Wishlist/ProductWishlistWidget'

import { HeaderContext } from './HeaderContext'

export interface HeaderDataModel {
  ':items': {
    countrylanguageswitcher: CountryLanguageSwitcher
    logo: LogoProperties
    navigation: {
      ':items': [NavigationSectionModel]
      teaser: NavigationTeaserModel
    }
    search: SearchConfig
    productWishlistWidget: ProductWishlistConfig
  }
}

interface HeaderStateProperties {
  headerData: DOMStringMap
  children: JSX.Element
}

const HeaderState: React.FC<HeaderStateProperties> = ({ headerData, children }): JSX.Element => {
  const [headerDropdownContent, setHeaderDropdownContent] = useState<
    'navigation' | 'languageSwitcher' | 'search' | 'productWishlistWidget'
  >('navigation')
  // TODO: maybe warp in useMemo in the future. results in a type error
  const headerDataJson = JSON.parse(headerData.cmpDataJson || '{ ":items": {}, ":itemsOrder": [] }')
  const { navigation } = headerDataJson[':items']
  const teaserContainerReference = useRef<HTMLDivElement>(null)
  const teaserContentReference = useRef<HTMLDivElement>(null)
  const [currentSectionId, setCurrentSectionId] = useState<null | string>(null)
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [isSubNavigationOpenOnMobile, setIsSubNavigationOpenOnMobile] = useState<boolean>(false)
  const [navigationTeaserData, setNavigationTeaserData] = useState<{
    teaser?: NavigationTeaserModel
  }>({
    teaser: navigation?.teaser,
  })
  const [topNavigationData, setTopNavigationData] = useState(null)

  const waitForTransition = (callback, reference): any => {
    if (teaserContentReference && teaserContentReference.current) {
      reference.current.addEventListener('transitionend', function listener() {
        callback()
        reference.current.removeEventListener('transitionend', listener)
      })
    }
  }

  const setIsHeaderOpened = (openHeader: boolean) => {
    setIsOpen(openHeader)
    if (navigation?.teaser?.link && !openHeader) {
      waitForTransition(() => {
        setNavigationTeaserData({ teaser: navigation?.teaser })
      }, teaserContainerReference)
    }
    document.body.classList.toggle('no-scroll', openHeader)
  }

  const value = useMemo(
    () => ({
      setIsHeaderOpened,
      setNavigationTeaserData,
      setHeaderDropdownContent,
      setTopNavigationData,
      setCurrentSectionId,
      setIsSubNavigationOpenOnMobile,
      headerDropdownContent,
      isHeaderOpened: isOpen,
      navigationTeaserData,
      headerDataJson,
      teaserContainerReference,
      teaserContentReference,
      topNavigationData,
      currentSectionId,
      isSubNavigationOpenOnMobile,
      waitForTransition,
    }),
    [isOpen, navigationTeaserData, topNavigationData, headerDropdownContent, isSubNavigationOpenOnMobile],
  )

  return <HeaderContext.Provider value={value}>{children}</HeaderContext.Provider>
}

export default HeaderState
